import React, { useEffect, useState } from "react";

import "../../Utility/style.css";
import Modal from "react-bootstrap/Modal";
import { Close, ChevronLeft, ChevronRight } from "react-bytesize-icons";
import SurveyFormList from "../../Components/SurveyFormList";
import Header from "../../Components/Header";
import SurveyFormFilter from "../../Components/SurveyFormFilter";

import {
  SURVEY_FORMLISTDATA,
  SURVEY_FORMLIST_TABLEHEADER_DATA,
  SURVEY_FORMLIST_HEADER_CHECKBOX,
  SURVEY_FORM_PRINTLIST,
  SEARCHINPUT_PLACEHOLDER,
  FORMLIST_MODE,
  INTIMATED_ACTIVE,
  INTIMATED_NON_ACTIVE,
  SURVEY_FILTERS,
  METADATA_SYNC,
  PAGINATION_PER_PAGE,
  PAGINATION_CURRENT_PAGE,
  PAGINATION_ALL,
} from "../../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import SurveyFormDownload from "../../Components/SurveyFormDownload";
import SurveyFormPrint from "../../Components/SurveyFormPrinter";
import Filters from "../../Assets/Image/Filters.png";
import PrintToDownload from "../../Assets/Image/PrintToDownload.png";
import Refresh from "../../Assets/Image/Refresh.png";
import DownloadFormsFiles from "../../Assets/Image/DownloadFormsFiles.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Error from "../Errors";

import api from "../../Config/api";
import LoadingOverlay from "react-loading-overlay";

import getMetadata from "../../Utility/getMetadata";
import {
  IntimatedClaimsHeader,
  NonIntimatedClaimsHeader,
} from "../../Constants/surveyFormListHeader";

const Home = (props) => {
  /**
   * API Integration
   */

  const [loading, setLoading] = useState(true);

  const [surveys, setSurveys] = useState([]);

  const [claimType, setClaimType] = useState("intimated");
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const surveyFormListReducer = useSelector(
    (state) => state.surveyFormListReducer
  );

  const headerReducer = useSelector((state) => state.headerReducer);
  const paginationReducer = useSelector((state) => state.pagination);
  const metadataReducer = useSelector((state) => state.metadata);

  const surveysAPICall = async () => {
    console.log("Filters ===>", surveyFormListReducer.surveyFilters);
    try {
      let params = {
        page: paginationReducer.currentPage,
        per_page: paginationReducer.perPage,
        type: surveyFormListReducer.formListMode,
      };

      const { status, ao, district, crop, date_to, date_from } =
        surveyFormListReducer.surveyFilters;

      params.status = status.length > 0 ? JSON.stringify(status) : undefined;
      params.ao = ao.length > 0 ? JSON.stringify(ao) : undefined;
      params.district =
        district.length > 0 ? JSON.stringify(district) : undefined;
      params.crop = crop.length > 0 ? JSON.stringify(crop) : undefined;
      params.date_to = date_to ? date_to : undefined;
      params.date_from = date_from ? date_from : undefined;

      const response = await api.get("/api/surveys", { params });

      console.log("Crop======>", params.crop);

      console.log("API Success ===>", response);

      dispatch({
        type: PAGINATION_ALL,
        payload: {
          perPage: paginationReducer.perPage,
          currentPage: response.data.meta.current_page,
          totalPages: response.data.meta.total_pages,
          totalCount: response.data.meta.total_count,
        },
      });

      return response.data.surveys.map((survey) => ({
        _id: survey.id,
        updated_date: survey.updated_at,
        AO: survey.user?.full_name,
        status: survey.survey_status,
        checked: false,
        id:
          surveyFormListReducer.formListMode === "intimated"
            ? survey.application_id
            : survey.survey_identifier,

        date:
          surveyFormListReducer.formListMode === "intimated"
            ? survey.date_of_intimation
            : survey.date_of_survey,
        district:
          survey?.survey_detail?.insured_area_detail?.district?.district_name,
        state: survey?.survey_detail?.insured_area_detail?.state?.state_name,
        crop:
          surveyFormListReducer.formListMode === "intimated"
            ? survey?.survey_detail?.insured_area_detail?.crop?.crop_name
            : metadataReducer.crop.filter(
                (c) => c.id === survey.actual_crop_id_id
              )[0]?.crop_name,
      }));
    } catch (err) {
      console.log("API Error ===>", err);
      setError(err.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (surveyFormListReducer.formListMode === "intimated") {
        handleIntimatedClaim();
      } else {
        handleNonIntimatedClaim();
      }

      DispatchSurveyFormData(await surveysAPICall());

      dispatch({
        type: METADATA_SYNC,
        payload: await getMetadata(),
      });

      setLoading(false);
    })();

    return () => setLoading(false);
  }, [
    // currentPage,
    // perPage,
    paginationReducer.perPage,
    paginationReducer.currentPage,
    surveyFormListReducer.formListMode,
    surveyFormListReducer.surveyFilters,
    surveyFormListReducer.surveyFormListHeader,
  ]);

  useEffect(() => {
    console.log("Surveys State ===>", surveys);
    if (surveyFormListReducer.formListMode == "intimated") {
      handleIntimatedClaim();
    } else {
      handleNonIntimatedClaim();
    }

    DispatchSurveyFormData(surveys);
  }, [surveys]);

  const [IsShowPDF, setIsShowPDF] = useState(false);
  const [IsShowFilter, setIsShowFilter] = useState(false);
  const [IsShowDownload, setIsShowDownload] = useState(false);
  const [IsShowPrint, setIsShowPrint] = useState(false);

  const DispatchSurveyFormData = (DispatchData) => {
    console.log("Dispatcher ===>", DispatchData);
    dispatch({
      type: SURVEY_FORMLISTDATA,
      payload: DispatchData,
    });
  };

  const DispatchHeaderData = (DispatchData) => {
    dispatch({
      type: SURVEY_FORMLIST_TABLEHEADER_DATA,
      payload: DispatchData,
    });
  };

  const DispatchSearchPlaceholder = (searchPlaceholder) => {
    dispatch({
      type: SEARCHINPUT_PLACEHOLDER,
      payload: searchPlaceholder,
    });
  };
  const DispatchFormListMode = (FormListMode) => {
    dispatch({
      type: FORMLIST_MODE,
      payload: FormListMode,
    });
  };
  const DispatchIntimatedClaim = () => {
    dispatch({
      type: INTIMATED_ACTIVE,
      payload: "IntimatedClaim_active",
    });
    dispatch({
      type: INTIMATED_NON_ACTIVE,
      payload: "IntimatedClaim_nonActive",
    });
  };
  const DispatchNonIntimatedClaim = () => {
    dispatch({
      type: INTIMATED_ACTIVE,
      payload: "IntimatedClaim_nonActive",
    });
    dispatch({
      type: INTIMATED_NON_ACTIVE,
      payload: "IntimatedClaim_active",
    });
  };

  const handleIntimatedClaim = () => {
    if (surveyFormListReducer.intimated_claim == "IntimatedClaim_nonActive") {
      DispatchSurveyFormData([]);
      setClaimType("intimated");

      DispatchIntimatedClaim();
      DispatchSearchPlaceholder("Search by Application Number");
      DispatchHeaderData(IntimatedClaimsHeader);
      DispatchFormListMode("intimated");
      dispatch({
        type: SURVEY_FORMLIST_HEADER_CHECKBOX,
        payload: false,
      });

      dispatch({
        type: PAGINATION_PER_PAGE,
        payload: Number.parseInt(10),
      });
    }
  };

  const handleNonIntimatedClaim = () => {
    if (
      surveyFormListReducer.nonIntimated_claim == "IntimatedClaim_nonActive"
    ) {
      DispatchSurveyFormData([]);
      setClaimType("non-intimated");

      DispatchNonIntimatedClaim();
      DispatchSearchPlaceholder("Search by Survey Number");
      DispatchHeaderData(NonIntimatedClaimsHeader);
      DispatchFormListMode("non-intimated");
      dispatch({
        type: SURVEY_FORMLIST_HEADER_CHECKBOX,
        payload: false,
      });

      dispatch({
        type: PAGINATION_PER_PAGE,
        payload: Number.parseInt(10),
      });
    }
  };

  const handleBodySurveyCheckBox = async (item) => {
    const checkedSurveys = surveyFormListReducer.SurveyFormList.map(
      (survey) => {
        return {
          ...survey,
          checked: survey._id === item ? !survey.checked : survey.checked,
        };
      }
    );

    const checked_ids = checkedSurveys
      .filter((survey) => survey.checked === true)
      .map((survey) => survey._id);

    if (checked_ids.length === surveyFormListReducer.SurveyFormList.length) {
      console.log("all selected");
      dispatch({
        type: SURVEY_FORMLIST_HEADER_CHECKBOX,
        payload: true,
      });
    } else {
      dispatch({
        type: SURVEY_FORMLIST_HEADER_CHECKBOX,
        payload: false,
      });
    }

    DispatchSurveyFormData(checkedSurveys);
    dispatch({
      type: SURVEY_FORM_PRINTLIST,
      payload: checked_ids,
    });
  };

  const hendleHeaderSurveyCheckBox = () => {
    const changeTo = !surveyFormListReducer.surveyFormHeaderCheckBox;

    const checkedSurveys = surveyFormListReducer.SurveyFormList.map(
      (survey) => {
        return {
          ...survey,
          checked: changeTo,
        };
      }
    );

    const checked_ids = checkedSurveys
      .filter((survey) => survey.checked === true)
      .map((survey) => survey._id);

    dispatch({
      type: SURVEY_FORMLIST_HEADER_CHECKBOX,
      payload: changeTo,
    });

    DispatchSurveyFormData(checkedSurveys);
    dispatch({
      type: SURVEY_FORM_PRINTLIST,
      payload: checked_ids,
    });
  };

  const perPageDropdown = [];
  for (let i = 0; i <= paginationReducer.totalCount / 10; i++) {
    const value = (i + 1) * 10;
    perPageDropdown.push({ text: `Show ${value}`, value });
  }

  const handlePerPageChange = (e) => {
    dispatch({
      type: PAGINATION_PER_PAGE,
      payload: Number.parseInt(e.target.value),
    });
    dispatch({
      type: SURVEY_FORMLIST_HEADER_CHECKBOX,
      payload: false,
    });
  };

  const handlePaginationPrev = () => {
    if (paginationReducer.currentPage > 1) {
      dispatch({
        type: PAGINATION_CURRENT_PAGE,
        payload: paginationReducer.currentPage - 1,
      });
      dispatch({
        type: SURVEY_FORMLIST_HEADER_CHECKBOX,
        payload: false,
      });
    }
  };

  const handlePaginationNext = () => {
    if (paginationReducer.currentPage < paginationReducer.totalPages) {
      dispatch({
        type: PAGINATION_CURRENT_PAGE,
        payload: paginationReducer.currentPage + 1,
      });
      dispatch({
        type: SURVEY_FORMLIST_HEADER_CHECKBOX,
        payload: false,
      });
    }
  };

  const handleCancelButton = () => {
    setIsShowFilter(!IsShowFilter);
    console.log("cancel");
  };

  const removeFilters = () => {
    dispatch({
      type: SURVEY_FILTERS,
      payload: {
        date_from: undefined,
        date_to: undefined,
        status: [],
        ao: [],
        district: [],
        crop: [],
      },
    });
  };

  const paginationText = () => {
    let startCount =
      surveyFormListReducer.SurveyFormList.length === 0
        ? 0
        : (paginationReducer.currentPage - 1) * paginationReducer.perPage + 1;

    let endCount = Math.min(
      paginationReducer.currentPage * paginationReducer.perPage,
      paginationReducer.totalCount
    );

    let totalCount = paginationReducer.totalCount;

    if (headerReducer.searchValue !== "") {
      const formCounts = surveyFormListReducer.SurveyFormList.filter((item) =>
        item.id.startsWith(headerReducer.searchValue)
      ).length;

      startCount = formCounts === 0 ? 0 : 1;
      endCount = formCounts;

      totalCount = endCount;
    }

    return `${startCount} - ${endCount} of ${totalCount}`;
  };

  if (error) {
    return <Error code={error} />;
  } else {
    return (
      <LoadingOverlay active={loading} spinner>
        <div className="surveyFormList_mainContainer">
          <div style={{}}>
            {/* Start Header Section */}
            <Header props={props} searchbar />

            {/* Start Pagination Section */}
            <div className="paginationContainer">
              <div className="col-lg-12 row">
                <div className="col-md-6 col-12 leftPagination_container">
                  <div className="col-12 row">
                    <div className="col-8">
                      <h1 className="claimSurvey_text">Claims & Surveys</h1>
                    </div>
                    {/*<div className="col-1">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => removeFilters()}
                      >
                        <Reload width={20} height={20} color="#687185" />
                        <img src={Refresh} style={{ width: 30, height: 30 }} />
                      </div> 
                    </div>*/}
                    <div className="col-2">
                      <div style={{ cursor: "pointer" }}>
                        {/* <Print width={20} height={20} color="#687185" /> */}
                        <OverlayTrigger overlay={<Tooltip>Print</Tooltip>}>
                          <img
                            onClick={() => {
                              setIsShowPrint(!IsShowPrint);
                              setIsShowFilter(false);
                              setIsShowDownload(false);
                            }}
                            src={PrintToDownload}
                            style={{ width: 30, height: 30 }}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>

                    {/* {selectOfficerRoleReducer.selectOfficerType != 'Coordinating-Officer' ? ( */}
                    <div className="col-2">
                      <div style={{ cursor: "pointer" }}>
                        <OverlayTrigger overlay={<Tooltip>Download</Tooltip>}>
                          <img
                            onClick={() => {
                              setIsShowDownload(!IsShowDownload);
                              setIsShowFilter(false);
                              setIsShowPrint(false);
                            }}
                            src={DownloadFormsFiles}
                            style={{ width: 30, height: 30 }}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>

                    {/* ) : null} */}
                  </div>
                </div>

                <div className="col-md-6 col-12 row rightPagination_container">
                  {/* <div className="col-12 row"> */}
                  <div
                    style={{ marginTop: "7px" }}
                    className="col-4 col-md-5 row justify-content-center"
                  >
                    <div className="col-3 d-lg-flex d-none">Filters</div>
                    <div
                      className="col-6 col-lg-2"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        onClick={() => {
                          setIsShowFilter(!IsShowFilter);
                          setIsShowPrint(false);
                          setIsShowDownload(false);
                        }}
                        src={Filters}
                        style={{ width: 30, height: 30 }}
                      />
                    </div>
                    <div
                      className="col-6 col-lg-2"
                      style={{ cursor: "pointers" }}
                    >
                      <OverlayTrigger
                        overlay={<Tooltip>Remove filters</Tooltip>}
                      >
                        <img
                          onClick={() => removeFilters()}
                          src={Refresh}
                          style={{ width: 30, height: 30 }}
                        />
                      </OverlayTrigger>
                    </div>
                    {/* <Options width={20} height={20} color="#687185" /> */}
                  </div>

                  <div className="col-4 col-md-3 pageCount_dropdown">
                    {/* <h1 className="claimSurvey_visibleClaimCount">
                      Show &nbsp; 10  <ChevronBottom width={15} height={10} color="#363636" />
                    </h1> */}
                    <select
                      name="per_page"
                      className="claimSurvey_visibleClaimCount"
                      onChange={handlePerPageChange}
                      // value={
                      //
                      //     ? paginationReducer.perPage
                      //     : perPageDropdown.slice(-1)[0]
                      // }
                    >
                      {perPageDropdown.map((drop, idx) => (
                        <option
                          key={idx}
                          value={drop.value}
                          selected={
                            paginationReducer.perPage <=
                            paginationReducer.totalCount
                              ? paginationReducer.perPage === drop.value
                              : drop.value ===
                                perPageDropdown.slice(-1)[0].value
                          }
                        >
                          {drop.text}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-2 ">
                    <h5 className="pagination_text">{paginationText()}</h5>
                  </div>

                  <div className="col-2 d-flex justify-content-between">
                    {/* <div className="col-12"> */}
                    <div
                      className="col-4"
                      style={{ cursor: "pointer", marginTop: "6px" }}
                      onClick={handlePaginationPrev}
                    >
                      <ChevronLeft width={20} height={15} color="#687185" />
                    </div>
                    <div
                      className="col-4"
                      style={{ cursor: "pointer", marginTop: "6px" }}
                      onClick={handlePaginationNext}
                    >
                      <ChevronRight width={20} height={15} color="#687185" />
                    </div>
                    {/* </div> */}
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="IntimatedClaim_container">
            <div className="IntimatedClaim_innercontainer">
              <div
                className={surveyFormListReducer.intimated_claim}
                onClick={() => handleIntimatedClaim()}
              >
                Intimated Claims
              </div>
              <div
                className={surveyFormListReducer.nonIntimated_claim}
                onClick={() => handleNonIntimatedClaim()}
              >
                Non-Intimated Claims
              </div>
            </div>
          </div>

          {/* Start Data Listing Section */}
          <div className="Listing_mainContainer">
            <SurveyFormList
              props={props}
              onClickCheckBox={(data) => {
                handleBodySurveyCheckBox(data);
              }}
              onClickHeaderCheckBox={() => {
                hendleHeaderSurveyCheckBox();
              }}
            />
          </div>

          {IsShowDownload ? (
            <SurveyFormDownload
              cancelButton={() => {
                setIsShowDownload(false);
              }}
            />
          ) : null}

          {IsShowPrint ? (
            <SurveyFormPrint
              cancelButton={() => {
                setIsShowPrint(false);
              }}
            />
          ) : null}

          {IsShowFilter ? (
            <SurveyFormFilter
              cancelButton={() => {
                handleCancelButton();
              }}
            />
          ) : null}

          <Modal
            dialogClassName="modal-90w"
            show={IsShowPDF}
            style={{ height: window.innerHeight }}
          >
            <div style={{ height: 150, margin: 10 }}>
              <div
                onClick={() => setIsShowPDF(!IsShowPDF)}
                style={{ width: "100%", textAlign: "right", padding: 15 }}
              >
                <Close width={15} height={15} color="#B3B6B7" />
              </div>

              <div style={{ textAlign: "center" }}>
                <h6 style={{ fontSize: "25px", fontWeight: "700" }}>
                  {surveyFormListReducer.surveyFormPrintList.length}{" "}
                  {surveyFormListReducer.surveyFormPrintList.length > 1
                    ? " Files"
                    : " File"}{" "}
                  Downloaded Sucessfully
                </h6>
              </div>
            </div>
            {/* Start Data Listing Section */}
            <div className="Listing_mainContainer">
              <SurveyFormList
                props={props}
                onClickCheckBox={(data) => {
                  handleBodySurveyCheckBox(data);
                }}
                onClickHeaderCheckBox={() => {
                  hendleHeaderSurveyCheckBox();
                }}
              />
            </div>
          </Modal>
        </div>
      </LoadingOverlay>
    );
  }
};

export default Home;
