// prettier-ignore

import React, {  useEffect, useState } from "react";
import "../../Utility/style.css";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import moment from "moment";

const SurveyFormList = (props) => {
  const surveyFormListReducer = useSelector(
    (state) => state.surveyFormListReducer
  );

  const [searchPrefix, setSearchPrefix] = useState("");

  const headerReducer = useSelector((state) => state.headerReducer);

  useEffect(() => {
    console.log(
      "surveyFormListHeader  ",
      surveyFormListReducer.surveyFormListHeader
    );

    console.log("surveyFormList  ", surveyFormListReducer.SurveyFilterFormList);
  }, []);

  useEffect(() => {
    setSearchPrefix(headerReducer.searchValue);
  }, [headerReducer.searchValue]);

  const statusObj = {
    is_new: { color: "#3A6BDA", text: "New" },
    active: { color: "#000", text: "Active" },
    in_progress: { color: "#FF9A36", text: "In Progress" },
    submitted: { color: "#7D7D7D", text: "Submitted" },
    complete: { color: "#3AE5E5", text: "Completed" },
  };

  const LinkWrapper = ({ children, link }) => (
    <>
      {React.Children.map(children, (child) => (
        <Link to={link}>{child}</Link>
      ))}
    </>
  );

  const rowFormJSX = (formItem, formIndex) => {
    return (
      <tr id={formItem._id} key={formIndex}>
        <td style={{ paddingLeft: "10px" }}>
          <input
            type="checkbox"
            onChange={() => props.onClickCheckBox(formItem._id)}
            style={{ cursor: "pointer" }}
            checked={formItem.checked}
          />
        </td>
        <LinkWrapper link={`Claim-Application-Details/${formItem._id}`}>
          <td>{formItem.id}</td>
          <td>
            {formItem.date
              ? moment(formItem.date).format("DD MMMM YYYY")
              : null}
          </td>
          <td>
            {formItem.district}
            {formItem.district ? "," : null} {formItem.state}
          </td>
          <td>{formItem.crop}</td>
          <td>{formItem.AO}</td>
          {/* {selectOfficerRoleReducer.selectOfficerType == 'Coordinating-Officer' ? ( */}
          <td>
            <div className="SurveyFormList_StatusDate_container">
              {formItem.status === "active" &&
              surveyFormListReducer.formListMode === "non-intimated" ? null : (
                <>
                  <div
                    className="SurveyFormList_StatusCircleText_container"
                    style={{
                      color: statusObj[formItem.status]?.color,
                    }}
                  >
                    <span
                      className="SurveyFormList_StatusCircle"
                      style={{
                        backgroundColor: statusObj[formItem.status]?.color,
                      }}
                    ></span>
                    {statusObj[formItem.status]?.text}
                  </div>
                  <br />
                  <b>
                    {formItem.status &&
                      moment(formItem.updated_date).format("DD MMMM YYYY")}
                  </b>
                </>
              )}
            </div>
          </td>
        </LinkWrapper>
      </tr>
    );
  };

  const displayRows = () => {
    let rowsJSX;
    let empty = true;
    if (surveyFormListReducer.SurveyFormList.length > 0) {
      rowsJSX = surveyFormListReducer.SurveyFormList.map(
        (formItem, formIndex) => {
          if (!formItem.id || formItem.id.startsWith(searchPrefix)) {
            empty = false;

            return rowFormJSX(formItem, formIndex);
          }

          return null;
        }
      );
    }

    if (empty) {
      rowsJSX = (
        <tr>
          <td
            colSpan={surveyFormListReducer.surveyFormListHeader.length + 1}
            style={{
              textAlign: "center",
              paddingTop: "1rem",
              paddingBottom: "1rem",
              fontSize: "1.2rem",
              fontStyle: "italic",
            }}
          >
            No records found!
          </td>
        </tr>
      );
    }

    return rowsJSX;
  };

  return (
    <>
      <div className="SurveyFormList_container  respo table-responsive">
        <table className="headTable">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={() => props.onClickHeaderCheckBox()}
                  style={{ cursor: "pointer" }}
                  checked={surveyFormListReducer.surveyFormHeaderCheckBox}
                />
              </th>
              {surveyFormListReducer.surveyFormListHeader.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>{displayRows()}</tbody>
        </table>
      </div>
    </>
  );
};

export default SurveyFormList;
