import React, { useEffect, useRef } from "react";
import "../../Utility/style.css";
import { useSelector } from "react-redux";
import api from "../../Config/api";

import { zipLimit } from "../../Constants/limits";

const SurveyFormDownload = (props) => {
  const surveyFormListReducer = useSelector(
    (state) => state.surveyFormListReducer
  );

  const handleDownload = async () => {
    const win = window.open("/Zipping?jobId=Loading");
    const applications_ids = surveyFormListReducer.surveyFormPrintList;
    console.log(applications_ids);
    if (applications_ids.length === 0) {
      win.location = `/Zipping?jobId=NoPayload`;
    } else if (applications_ids.length > zipLimit) {
      win.location = `/Zipping?jobId=CannotProcessPayload`;
    } else {
      try {
        const response = await api.post("/api/lambda_functions/download_zip", {
          survey_ids: applications_ids,
        });
        console.log(response);

        win.location = `/Zipping?jobId=${response.data.job_id}`;
      } catch (err) {
        console.log("Error", err);
        win.location = `/Zipping?jobId=Failed`;
      }
    }
  };

  // click handlers
  const node = useRef();
  useEffect(() => {
    const handleClick = (e) => {
      if (!node.current.contains(e.target)) {
        props.cancelButton();
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  return (
    <>
      <div className="surveyFormFilter_download" ref={node}>
        <p style={{ cursor: "pointer" }} onClick={handleDownload}>
          Download Files
        </p>
      </div>
    </>
  );
};

export default SurveyFormDownload;
